var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-card',[_c('v-card-title',[_vm._v(" Manifiestos creados "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('div',{staticClass:"mx-0"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length == 1),expression:"selected.length == 1"}],attrs:{"color":"primary","fab":"","x-small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""},on:{"click":_vm.fetchData}},[_vm._v("Buscar")])],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column col-4"},[_c('span',{staticClass:"text-h6 font-weight-regular black--text"},[_c('i',{staticClass:"icon-filter_calendar"}),_vm._v(" Filtro por rango de fecha")]),_c('v-menu',{ref:"menu1",staticStyle:{"border-bottom":"1px solid #000"},attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","placeholder":"Todos","solo":"","readonly":""},on:{"click:clear":_vm.limpiarRango},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"id":"datepicker-starkoms","range":""},on:{"input":_vm.formatearRango},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('div',{staticClass:"d-flex flex-column col-4"},[_c('span',{staticClass:"text-h6 font-weight-regular black--text"},[_c('i',{staticClass:"icon-filter_states"}),_vm._v(" Filtro por courier")]),_c('v-select',{staticClass:"ml-2 mr-2",attrs:{"label":"Filtro por courier","items":_vm.carrierList,"item-text":"text","solo":""},on:{"input":_vm.fetchData},model:{value:(_vm.selectCourier),callback:function ($$v) {_vm.selectCourier=$$v},expression:"selectCourier"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.search || !!_vm.dateRangeText  || _vm.selectCourier != 'Todos'),expression:"!!search || !!dateRangeText  || selectCourier != 'Todos'"}],staticClass:"align-self-center",attrs:{"dark":"","color":"grey"},on:{"click":_vm.limpiarFiltros}},[_vm._v("Limpiar Filtros")])],1)])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":'id',"sort-desc":true,"disable-pagination":true,"hide-default-footer":true,"loading":_vm.dataTableLoading},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$moment(item.updated_at).format('LLLL'))+" ")])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"})]}},{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.pdf !== '')?_c('v-btn',{attrs:{"large":"","color":"warning","dark":""},on:{"click":function($event){return _vm.openDialogShipmentsFromManifests(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-file")]),_vm._v("Ver Envíos")],1):_vm._e()],1),(item.pdf !== '')?_c('td',[(item.pdf !== '')?_c('v-btn',{attrs:{"large":"","color":"success","dark":""},on:{"click":function($event){return _vm.openPdfLink(item.pdf)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-file")]),_vm._v("Ver manifiesto")],1):_vm._e()],1):_vm._e()]}},{key:"item.botones",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"font-size":"2rem !important"},attrs:{"small":""},on:{"click":function($event){return _vm.openModalCargarManifiestoFirmado(item)}}},on),[_vm._v(" icon-file_upload ")])]}}],null,true)},[_c('span',[_vm._v("Cargar manifiesto firmado")])]),(item.signed_pdf !== '' && item.signed_pdf != null )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.signed_pdf !== '' && item.signed_pdf != null)?_c('v-btn',{attrs:{"large":"","color":"#116cf6","dark":""},on:{"click":function($event){return _vm.openPdfLink(item.signed_pdf)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-note-search-outline")]),_vm._v("Ver manifiesto Firmado")],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ver manifiesto firmado")])]):_vm._e()],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-select',{staticStyle:{"max-width":"120px"},attrs:{"label":"Registros por página","items":[10,20,50,100, 500]},on:{"input":_vm.fetchData},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":100,"color":"#e55d43"},on:{"input":_vm.fetchData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)])]),_c('dialog-shipments-from-manifests'),_c('modal-cargar-manifiesto-firmado',{on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }