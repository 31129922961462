<template>
    <div>
        <v-app>
            <v-dialog v-model="showDialog" max-width="1000px">
                <v-card v-if="showDialog">             
                    <v-card-title>
                        Envíos del manifiesto {{manifest_id}}
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between mb-4">
                                    <v-text-field v-model="search" clearable label="Buscar" hide-details dense solo class="mr-2" ></v-text-field>
                                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;"  >Buscar</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="tableData"
                            :search="search"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            class="elevation-1 datatable"
                            sort-by="id"
                            :sort-desc="true"
                        >
                            <template v-slot:item.order_id="{ item }">
                                <td class="text-center"> {{ item.order_id }} </td>
                            </template>
                            <template v-slot:item.order_reference="{ item }">
                                <td class="text-center"> {{ item.order_reference }} </td>
                            </template>
                            <template v-slot:item.shipping_number="{ item }">
                                <td class="text-center"> {{ item.shipping_number }} </td>
                            </template>
                            <template v-slot:item.fuente="{ item }">
                                <td class="text-center"> {{ item.source ? item.source.name : 'Sin asignar' }} </td>
                            </template>
                            <template v-slot:item.carrier_name="{ item }">
                                <td class="text-center"> {{ item.carrier_name }} </td>
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                <td class="text-center"> {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }} </td>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions><v-btn color="primary" text @click="showDialog = false" > Cerrar </v-btn></v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            order_reference: undefined,
            tableData: [],
            orderSubStatesList: [],
            search: '',
        }
    },
    created(){        
        this.$parent.$on('openDialogShipmentsFromManifests', (datos) => {
            this.manifest_id = datos.id;
            this.fetchData(datos);
        });
    },
    methods: {
        fetchData(datos){
            var vm = this;
            this.axios({
                url: 'shippings/get_by_manifest_id/'+datos.id
            }).then( response => {
                vm.tableData = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '# de orden',  align: 'left',  sortable: true, value: 'order_id' },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Seguimiento', value: 'shipping_number' },
                { text: 'Fuente', value: 'source.name'},
                { text: 'Carrier', value: 'carrier_name' },
                { text: 'Fecha', value: 'created_at' },
            ];
        }
    }
}
</script>