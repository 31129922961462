<template>
    <div>
        <v-app>
            <div class="row">
                <div class="col">
                    <v-card>
                        <v-card-title>
                            Manifiestos creados
                            <v-spacer></v-spacer>
                            
                                <v-btn class="" @click="fetchData"  text icon color="blue">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            <div class="mx-0">
                                <v-btn  color="primary" fab x-small dark v-show="selected.length == 1">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-card outlined tile elevation="1">
                                <v-card-text style="padding-bottom: 0px;">
                                    <div class="d-flex justify-space-between mb-4">
                                        <v-text-field
                                        v-model="search"
                                        clearable
                                        label="Buscar"
                                        hide-details
                                        dense
                                        solo
                                        class="mr-2"
                                        @change="fetchData"
                                        ></v-text-field>
                                        <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchData" >Buscar</v-btn>
                                    </div>
                                    <div class="d-flex">
                                        <div class="d-flex flex-column col-4">
                                        <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                                        <v-menu
                                            ref="menu1" 
                                            v-model="menu1" 
                                            :close-on-content-click="false"
                                            transition="scale-transition" 
                                            offset-y max-width="290px"
                                            min-width="290px" 
                                            style="border-bottom: 1px solid #000;"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                                @click:clear="limpiarRango"
                                                v-model="dateRangeText"
                                                placeholder="Todos"
                                                solo
                                                readonly
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                                        </v-menu>
                                        </div>
                                        <div class="d-flex flex-column col-4">
                                        <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_states"></i> Filtro por courier</span>
                                        <v-select
                                            class="ml-2 mr-2"
                                            label="Filtro por courier"
                                            :items="carrierList"
                                            v-model="selectCourier"
                                            item-text="text"
                                            @input="fetchData"
                                            solo
                                        
                                        ></v-select>
                                        </div>
                                        
                                        <div class="d-flex">
                                        <v-btn
                                            v-show="!!search || !!dateRangeText  || selectCourier != 'Todos'"
                                            @click="limpiarFiltros"
                                            class="align-self-center" dark color="grey"
                                        >Limpiar Filtros</v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                        <v-card-text>
                            <v-data-table
                                :headers="headers"
                                :items="tableData"
                                :page.sync="page"
                                :items-per-page="itemsPerPage"
                                v-model="selected"
                                no-results-text="No hay resultados disponibles"
                                no-data-text="No hay datos disponibles"
                                loading-text="Cargando datos..."
                                :sort-by="'id'"
                                :sort-desc="true"
                                class="elevation-1 datatable"
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                :loading="dataTableLoading"
                            >
                            <template v-slot:item.updated_at="{ item }">
                                <td
                                    class="text-center">{{$moment(item.updated_at).format('LLLL')}}
                                </td>
                            </template>
                                <template v-slot:item.estado="{ item }">
                                    <td class="text-center">
                                        <!--
                                        <b-button size="sm" v-if="item.name_order_state == 'Ingresado'" pill variant="success">Ingresado</b-button>
                                        <b-button size="sm" v-if="item.name_order_state == 'Preparando'" pill variant="info">Preparando</b-button>
                                        <b-button size="sm" v-if="item.name_order_state == 'Listo para enviar'" pill variant="info">Listo para enviar</b-button>                               
                                        <b-button size="sm" v-if="item.name_order_state == 'Entregado a courier'" pill variant="info">Entregado a courier</b-button>
                                        <b-button size="sm" v-if="item.name_order_state == 'En tránsito'" pill variant="info">En tránsito</b-button>
                                        <b-button size="sm" v-if="item.name_order_state == 'Entregado'" pill variant="info">Entregado</b-button>
                                        -->
                                    </td>
                                </template>
                                <template v-slot:item.acciones="{ item }">
                                    <td>
                                        <v-btn large color="warning" dark v-if="item.pdf !== ''" @click="openDialogShipmentsFromManifests(item)" >
                                        <v-icon left dark>mdi-file</v-icon>Ver Envíos</v-btn>
                                    </td>

                                    <td v-if="item.pdf !== ''">
                                        <v-btn large color="success" dark v-if="item.pdf !== ''" @click="openPdfLink(item.pdf)" >
                                        <v-icon left dark>mdi-file</v-icon>Ver manifiesto</v-btn>
                                    </td>
                                </template>
                                <template v-slot:item.botones="{ item }">
                                <td class="d-flex">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon style="font-size: 2rem !important;" small class="mr-2" v-on="on" @click="openModalCargarManifiestoFirmado(item)" >
                                                icon-file_upload
                                            </v-icon>
                                        </template>
                                        <span>Cargar manifiesto firmado</span>
                                    </v-tooltip>

                                    <v-tooltip bottom v-if="item.signed_pdf !== '' && item.signed_pdf != null ">
                                        <template v-slot:activator="{ on }">
                                             <v-btn large color="#116cf6" dark v-if="item.signed_pdf !== '' && item.signed_pdf != null" @click="openPdfLink(item.signed_pdf)">
                                        <v-icon left dark> mdi-note-search-outline</v-icon>Ver manifiesto Firmado</v-btn>
                                        </template>
                                        <span>Ver manifiesto firmado</span>
                                    </v-tooltip>

                                </td>
                                </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                                <v-select
                                style="max-width: 120px"
                                @input="fetchData"
                                v-model="itemsPerPage"
                                label="Registros por página"
                                :items="[10,20,50,100, 500]"
                                ></v-select>
                                <v-pagination
                                @input="fetchData"
                                v-model="page"
                                :length="totalPages"
                                :total-visible="100"
                                color="#e55d43"
                                ></v-pagination>
                            </div>

                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </v-app>
        <dialog-shipments-from-manifests></dialog-shipments-from-manifests>
        <modal-cargar-manifiesto-firmado @success="fetchData"></modal-cargar-manifiesto-firmado>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
import DialogShipmentsFromManifests from "@/views/oms/Envios/DialogShipmentsFromManifests";
import ModalCargarManifiestoFirmado from "@/views/oms/Envios/ModalCargarManifiestoFirmado";
export default {
    components: {
        DialogShipmentsFromManifests,
        ModalCargarManifiestoFirmado
    },
    data () {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            totalPages: 1,
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            carrierList: [],
            selectCarrier: undefined,
            search: '',
            selected: [],
            
            tableData: [],
            dates: [],
            menu1: false,
            dateRangeText: '',

            selected: [],
            couriersList: [],
            selectCourier: 'Todos',
            dataTableLoading: false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Manifiestos", route: "manifiestos" },
        ]);
        this.fetchCarrierList();
        this.fetchData();
        // this.fetchCouriers();
    },
    methods: {
        fetchCarrierList()
        {
            var vm = this;
            this.axios({
                url: 'shippings/carriers/get_data_list',
                method: 'GET'
            }).then(response => {
                
                vm.carrierList = response.data;
                vm.carrierList.unshift({text: 'Todos', value: 0});
                
            }).catch(error => {
                console.log(error);
            })
        },
        limpiarFiltros() {
            this.search = "";
            this.dateRangeText = "";
            this.selectCourier = "Todos";
            this.fetchData();
        },
        fetchData()
        {
            this.dataTableLoading = true;
             var vm = this;

            const sortBy = "id";
            const sortDesc = true;
            const page = this.page;
            const itemsPerPage = this.itemsPerPage;
            const search = this.search;
            // const filterState = this.orderStateList.find(e => e.name == this.selectOrderState) ? this.orderStateList.find(e => e.name == this.selectOrderState).id : null;
            // const filterWarehouse = this.warehousesList.find(e => e.name == this.selectWarehouses) ? this.warehousesList.find(e => e.name == this.selectWarehouses).id : null;
            const selectCarrier = this.selectCourier != 'Todos' && this.selectCourier ? this.selectCourier : null;
            const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
            const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
            
            this.axios({
                url: 'shippings/manifests/json', 
                method: 'GET',
                params: {
                        page,
                        perPage: itemsPerPage,
                        sortBy: sortBy,
                        sortDesc: sortDesc ? 1 : 0,
                        search,
                        startDate,
                        endDate,
                        selectCarrier
                    },
            }).then( response => {
                // let arreglo = [];
                vm.tableData = response.data.data.map((e) => e);
                vm.totalPages = response.data.last_page;
                vm.dataTableLoading = false;
                vm.selected = [];
            }).catch( error => {
                console.log(error);

                vm.dataTableLoading = false;
            })
        },
        filtroRangoFechas(value){
            if( !this.dateRangeText || !this.dates.length ){
                return true;
            }else{
                if( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1])) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        verRefSelect()
        {
            console.log('select change',this.$refs.select_carrier.selectedItems);
        },
        filtroCarrier(v)
        {   
            if( this.$refs.select_carrier.selectedItems[0] ){
                if(this.$refs.select_carrier.selectedItems[0].text == 'Todos') return true;
                return this.$refs.select_carrier.selectedItems[0].text == v
            } else {
                return true;
            }
        },
        formatearRango()
        {
           if (!this.dates.length) {
            this.dateRangeText = "";
            } else {
            this.dateRangeText = this.dates.join(" ~ ");
            }
            this.fetchData();
        },
        limpiarRango() {
            this.dates = [];
        },
        fetchCouriers(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'shippings/couriers/data_select_input/'+'1'
            }).then( response => {
                vm.couriersList = response.data;
            }).catch( error => {
                console.log(error);
            });
        },
        openPdfLink(url){
            window.open(url, '_blank');
        },
        openDialogShipmentsFromManifests(item) {
            this.$emit("openDialogShipmentsFromManifests", item);
        },
        openModalCargarManifiestoFirmado(item) {
            this.$emit("openModalCargarManifiestoFirmado", item);
        },
    },
    computed: {
        headers(){ 
            return [
                { text: '#...',align: 'left', sortable: true,value: 'id'},
                { text: 'Fecha', value: 'updated_at'},
                { text: 'Courier', value: 'carrier_name' },
                { text: 'Manifiesto', value: 'acciones', sortable: false },
                { text: 'Manifiesto Firmado', value: 'botones', sortable: false },
            ];
        }
    }
}
</script>

<style scoped>
.v-btn {
    margin: 0px 1px;
}
.icon-file_upload:before {
    color: #8c8c8c;
}
</style>