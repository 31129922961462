var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c('v-card',[_c('v-card-title',[_vm._v(" Envíos del manifiesto "+_vm._s(_vm.manifest_id)+" ")]),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""}},[_vm._v("Buscar")])],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order_id)+" ")])]}},{key:"item.order_reference",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order_reference)+" ")])]}},{key:"item.shipping_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.shipping_number)+" ")])]}},{key:"item.fuente",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.source ? item.source.name : 'Sin asignar')+" ")])]}},{key:"item.carrier_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.carrier_name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a'))+" ")])]}}],null,false,657650133)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Cerrar ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }